module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/html/locales","languages":["en","de"],"defaultLanguage":"de","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"redirect":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/ignored-page","languages":["en"]},{"matchPath":"/:lang?/(contact|the-house|literature-award|offerings-of-the-house|gallery|events)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gerhart-Hauptmann-Haus Hiddensee","short_name":"Gerhart Hauptmann","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo_haus_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3bccb86d387f8968c1ad0b1ea2a6cc2e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
